import { supplierService, supplierClassifyService, supplierLevelService } from '@/api';
import { translation, convertProvinces, messageError } from '@/utils';
import { SupplierResource } from '@/resource/model';
import { Component, Prop } from 'vue-property-decorator';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';
import { OsAddress } from '@/components';

@Component({
  components: {}
})
export default class AddSupplier extends mixins(DialogMixin) {
  /**
   * 企业信息数据对象
   */
  @Prop({ required: false }) public supplier: SupplierResource | null = null;

  /**
   * 分类数据
   */
  public categoryList: Array<{ label: string; value: number }> = [];

  /**
   * 等级数据
   */
  public gradeList: Array<{ label: string; value: number }> = [];

  /**
   * 表单字段栅格数
   */
  public formColSpan = 8;

  /**
   * 表单数据对象
   */
  public formData: {
    companyName: string;
    fullName: string;
    categoryId: number | null;
    paymentDay: number | null;
    domainName: string;
    gradeId: string | null;
    provinces: Array<string>;
    address: string;
    servant: string;
    remark: string;
  } = {
    paymentDay: null,
    fullName: '',
    companyName: '',
    categoryId: null,
    domainName: '',
    gradeId: null,
    provinces: [],
    address: '',
    servant: '',
    remark: ''
  };

  /**
   * 表单验证规则
   */
  public formRules = {
    companyName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplier.inputName')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          supplierService
            .checkCompanyNameRepeat(value, this.supplier?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.supplierNameRepeat'));
                return;
              }

              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    fullName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplier.inputFullName')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ],
    domainName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplier.inputDomainName')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          supplierService.checkDomainNameRepeat(value, this.supplier?.id).then((isRepeat: boolean) => {
            if (isRepeat) {
              callback(translation('requestError.supplierDomainNameRepeat'));
              return;
            }

            callback();
          });
        },
        trigger: 'blur'
      }
    ],
    categoryId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplier.selectCategory')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ],
    gradeId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplier.selectLevel')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ],
    servant: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplier.inputServant')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ],
    provinces: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('supplier.selectArea')));
            return;
          }

          callback();
        },
        trigger: 'change'
      }
    ]
  };

  /**
   * 操作
   */
  public isCreate: boolean | null = true;

  /**
   * 初始化标记
   */
  private isInitalized = false;

  /**
   * dialog开启事件
   */
  public handleDialogOpen(): void {
    this.init();

    if (!this.supplier) {
      this.isCreate = true;
      this.title = 'supplier.addSupplier';
      return;
    }

    this.isCreate = false;
    this.title = 'supplier.editSupplier';

    const provinces: Array<string> = [];
    this.supplier.province && provinces.push(this.supplier.province);
    this.supplier.city && provinces.push(this.supplier.city);
    this.supplier.district && provinces.push(this.supplier.district);
    (this.$refs.osAddress as OsAddress)?.reload();
    this.$nextTick(() => {
      Object.assign(this.formData, this.supplier);
      this.formData.provinces = provinces;
    });
  }

  /**
   * 提交数据
   */
  public handleSubmit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }

      this.openSubmitLoading();

      try {
        const provinces = convertProvinces(this.formData.provinces);
        const form = { ...this.formData, ...provinces } as any;

        if (this.isCreate) {
          await supplierService.post(form);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
        } else {
          await supplierService.put(form);
          Message.success(translation('operationRes.editSuccess'));
          this.$emit('edit-success', form);
        }

        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.closeSubmitLoading();
      }
    });
  }

  /**
   * dialog关闭事件
   */
  public handleDialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.form as Form).resetFields();
  }

  /**
   * 初始化dialog所需数据
   */
  private init(): void {
    // 判断是否已初始化
    if (this.isInitalized) return;

    this.loadCategoryData();
    this.loadLevelData();
  }

  /**
   * 载入分类数据
   */
  private async loadCategoryData(): Promise<void> {
    try {
      const categoryList = (await supplierClassifyService.getAllEnableList()) || [];
      this.categoryList = categoryList.map(category => {
        return {
          label: category.categoryName,
          value: category.id
        };
      });
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 载入等级数据
   */
  private async loadLevelData(): Promise<void> {
    try {
      const gradeList = (await supplierLevelService.getAllSupplierLevels()) || [];
      this.gradeList = gradeList.map(grade => {
        return {
          label: grade.name,
          value: grade.id
        };
      });
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 开启loading
   */
  private openSubmitLoading(): void {
    this.submitLoading = true;
  }

  /**
   * 关闭loading
   */
  private closeSubmitLoading(): void {
    this.submitLoading = false;
  }
}
