import { ImportFile } from '@/views/dialogs/index';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { Paging } from '@/api/base';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  downloadFileByBlob,
  getStatusClass,
  getStatusI18Key,
  translation,
  showWarningConfirm,
  handleImportError,
  messageError
} from '@/utils';
import { ResourceStatusEnum } from '@/resource/enum';
import { supplierService, supplierClassifyService, supplierLevelService } from '@/api';
import { Message } from 'element-ui';
import { SupplierResource, SupplierClassifyResource, SupplierLevelResource, ImportRes } from '@/resource/model';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { ApiResponse } from '@/api/axios';
import AddSupplier from './add-supplier/add-supplier.vue';
import ModifySupplierLevel from './modify-supplier-level/modify-supplier-level.vue';

@Component({
  components: {
    OsTable,
    OsTableQuery,
    OsPagination,
    OsTableOperation,
    AddSupplier,
    ImportFile,
    ModifySupplierLevel
  }
})
export default class Supplier extends Vue {
  /**
   * 表格配置
   */
  public tableOption: OsTableOption<SupplierResource> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { prop: 'createTime', order: 'descending' }
  };

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<SupplierResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'companyName',
      label: 'supplier.companyName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'fullName',
      label: 'supplier.fullName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'domainName',
      label: 'supplier.domainName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'gradeName',
      label: 'supplier.level',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'categoryName',
      label: 'supplier.category',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'supplier.address',
      minWidth: '240px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `
        ${(row as SupplierResource).province}
        ${(row as SupplierResource).city ? (row as SupplierResource).city : ''}
        ${(row as SupplierResource).district ? (row as SupplierResource).district : ''}
        ${(row as SupplierResource).address}
        `;
      }
    },
    {
      prop: 'servant',
      label: 'supplier.servant',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'paymentDay',
      label: 'supplier.paymentDay',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'supplier.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '200px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 查询表单数据对象
   */
  public queryForm: Partial<{
    keywords: string;
    categoryId: number | null;
    status: ResourceStatusEnum | null;
    gradeId: number | null;
  }> = {
    keywords: '',
    categoryId: null,
    status: null,
    gradeId: null
  };

  /**
   * 表格查询表单配置
   */
  public queryFormOptions: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'companyName',
      label: 'supplier.companyName',
      option: {
        placeholder: 'supplier.companyName'
      }
    },
    {
      type: 'Input',
      field: 'fullName',
      label: 'supplier.fullName',
      option: {
        placeholder: 'supplier.fullName'
      }
    },
    {
      type: 'Select',
      field: 'categoryId',
      label: 'supplier.category',
      option: {
        placeholder: 'supplier.selectCategory',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: (): Array<any> => {
        return [
          {
            label: translation('common.startUsing'),
            value: 1
          },
          {
            label: translation('common.disabled'),
            value: 2
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'gradeId',
      label: 'supplier.level',
      option: {
        placeholder: 'supplier.selectLevel'
      },
      optionData: []
    }
  ];

  /**
   * 表格操作栏
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'supplier:save',
      handleClick: (): void => {
        this.openDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'supplier:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      plain: true,
      disabled: true,
      permissionCode: 'supplier:batchDelete',
      handleClick: (): void => {
        this.batchDelete();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      disabled: true,
      permissionCode: 'supplier:editStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.using);
      }
    },
    {
      type: 'danger',
      slot: 'end',
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      plain: true,
      disabled: true,
      permissionCode: 'supplier:editStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.disabled);
      }
    }
  ];

  /**
   * 列操作配置
   */
  public rowOperationOption: RowOperation<SupplierResource> = {
    width: '280px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'supplier:edit',
        handleClick: (row: SupplierResource): void => {
          this.openDialog(row);
        }
      },
      {
        operationType: 'modifyLevel',
        type: 'text',
        label: 'supplier.modifyLevel',
        icon: 'el-icon-edit',
        permissionCode: 'supplier:editGrade',
        handleClick: (row: SupplierResource): void => {
          this.openModifyLevelDialog(row);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'supplier:delete',
        handleClick: (row: SupplierResource): void => {
          this.delete(row);
        }
      }
    ]
  };

  /**
   * 【更多】配置
   */
  public moreMenuOptions: Array<{ label: string; routeLink: string; permissionCode: string }> = [
    {
      label: 'route.department',
      routeLink: '/supplier-department',
      permissionCode: 'supplier:department:list'
    },
    {
      label: 'route.position',
      routeLink: '/supplier-position',
      permissionCode: 'supplier:position:listPage'
    },
    {
      label: 'route.role',
      routeLink: '/supplier-role',
      permissionCode: 'supplier:role:listPage'
    },
    {
      label: 'route.personnel',
      routeLink: '/supplier-personnel',
      permissionCode: 'supplier:user:listPage'
    },
    // {
    //   label: 'route.printingMaterial',
    //   routeLink: '/printing-material',
    //   permissionCode: 'supplier:printing:material:listPage'
    // },
    // {
    //   label: 'route.backendMaterial',
    //   routeLink: '/backend-material',
    //   permissionCode: 'supplier:backend:material:listPage'
    // },
    {
      label: 'route.supplierProduct',
      routeLink: '/supplier-product',
      permissionCode: 'supplier:product:listPage'
    },
    {
      label: 'route.supplierDevice',
      routeLink: '/supplier-device',
      permissionCode: 'supplier:device:listPage'
    } /* ,
    {
      label: 'route.contacts',
      routeLink: '/supplier-contacts',
      permissionCode: ''
    } */
  ];

  /**
   * 分页配置
   */
  public pagintaionOption: Paging = {
    currentPage: 1,
    showCount: 10
  };

  /**
   * 表格总记录数
   */
  public totalData = 0;

  /**
   * 新增/编辑dialog显示状态
   */
  public dialogVisible = false;

  /**
   * 编辑数据对象
   */
  public editRow: SupplierResource | null = null;

  /**
   * 导入dialog显示状态控制
   */
  public importDialogVisible = false;

  /**
   * 导入dialog 标题
   */
  public importTitle = 'dialog.importSupplier';

  /**
   * 修改等级dialog显示状态控制
   */
  public modifyLevelDialogVisible = false;

  /**
   * 表格选中数据
   */
  private selectedRows: Array<SupplierResource> = [];

  /**
   * 查询按钮点击事件
   */
  public handleQueryClick(): void {
    this.loadData();
  }

  /**
   * 分页事件
   */
  public handlePagination(): void {
    this.loadData();
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(rows: Array<SupplierResource>): void {
    this.selectedRows = rows;
  }

  /**
   * 加载表格数据
   */
  public loadData(): void {
    this.tableOption.loading = true;
    supplierService
      .getList(this.queryForm as SupplierResource, this.pagintaionOption)
      .then((res: { data: SupplierResource[]; total: number }) => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 重新加载
   */
  public reload(): void {
    this.pagintaionOption.currentPage = 1;
    (this.$refs.supplierTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public getStatusClass(status: number): string {
    return getStatusClass(status);
  }

  public getStatusI18Key(status: number): string {
    return getStatusI18Key(status);
  }

  /**
   * vue创建完成钩子
   */
  public created(): void {
    this.loadQueryCategoryList();
    this.loadQueryGradeList();
    this.loadData();
  }

  /**
   * 批量更新状态
   * @param status 状态
   */
  public batchUpdateStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    supplierService.batchUpdateStatus(idList, status).then(() => {
      this.reload();
      Message.success(
        status === ResourceStatusEnum.using ? translation('common.usingSuccess') : translation('common.disabledSuccess')
      );
    });
  }

  /**
   * dialog关闭事件
   */
  public handleDialogClosed(): void {
    this.editRow = null;
  }

  /**
   * 编辑保存成功事件
   * 更新表格数据
   */
  public handleEditSuccess(data: SupplierResource): void {
    const findItem = this.tableOption.data.find(row => row.id === data.id);
    Object.assign(findItem, data);
  }

  /**
   * 等级修改dialog关闭事件
   */
  public handleModifyLevelDialogClosed(): void {
    this.editRow = null;
  }

  /**
   * 打开等级修改窗口
   * @param data 编辑数据
   */
  public openModifyLevelDialog(data: SupplierResource): void {
    this.editRow = data;
    this.modifyLevelDialogVisible = true;
  }

  /**
   * 下载导入模板
   */
  public downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    supplierService
      .downloadTemplate()
      .then((blob: any) => {
        downloadFileByBlob(translation('supplier.templateName'), blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }

  /**
   * 上传成功事件
   */
  public handleImportSuccess(path: string): void {
    supplierService
      .import(path)
      .then(() => {
        this.reload();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  /**
   * 更多菜单事件
   */
  public handleCommand(command: { routeLink: string; rowData: SupplierResource }): void {
    this.$router.push({
      path: command.routeLink,
      query: {
        companyId: command.rowData.id.toString()
      }
    });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<SupplierResource>): void {
    const allowedOpationArray = ['add', 'import'];
    this.tableToolbarOptions.forEach(button => {
      if (allowedOpationArray.indexOf(button.operationType) === -1) {
        button.disabled = value.length === 0;
      }
    });
    this.tableToolbarOptions = [...this.tableToolbarOptions];
  }

  /**
   * 初始化查询用供应商分类数据
   */
  private async loadQueryCategoryList(): Promise<void> {
    const data: Array<SupplierClassifyResource> = (await supplierClassifyService.getAll()) || [];

    const categoryList = data.map(category => {
      return {
        label: category.categoryName,
        value: category.id
      };
    });

    const option = this.queryFormOptions.find(option => option.field === 'categoryId');
    option && (option.optionData = categoryList);
  }

  /**
   * 初始化查询用供应商等级数据
   */
  private async loadQueryGradeList(): Promise<void> {
    const data: Array<SupplierLevelResource> = (await supplierLevelService.getAllSupplierLevels()) || [];

    const gradeList = data.map(level => {
      return {
        label: level.name,
        value: level.id
      };
    });

    const option = this.queryFormOptions.find(option => option.field === 'gradeId');
    option && (option.optionData = gradeList);
  }

  /**
   * 删除供应商
   */
  private delete(row: SupplierResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await supplierService.delete(row.id);
          Message.success(translation('operationRes.deleteSuccess'));
          this.reload();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  /**
   * 批量删除供应商
   */
  private batchDelete(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList = this.selectedRows.map(row => row.id);
          await supplierService.batchDelete(idList);
          Message.success(translation('operationRes.deleteSuccess'));
          this.reload();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  /**
   * 打开dialog
   * @param row 编辑数据
   */
  private openDialog(row: SupplierResource | null = null): void {
    this.editRow = row;
    this.dialogVisible = true;
  }

  /**
   * 打开导入窗口
   */
  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }
}
