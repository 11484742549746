import { supplierService, supplierLevelService } from '@/api';
import { SupplierResource } from '@/resource/model';
import { translation, messageError } from '@/utils';
import { Form, Message } from 'element-ui';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ModifySupplierLevel extends Vue {
  /**
   * dialog 显示状态
   */
  @PropSync('visible', { default: false }) public dialogVisible!: boolean;
  /**
   * 企业信息数据对象
   */
  @Prop() public supplier: SupplierResource | null = null;

  /**
   * dialog 标题
   */
  public title = '';
  /**
   * 提交loading状态
   */
  public submitLoading = false;

  /**
   * 等级数据
   */
  public gradeList: Array<{ label: string; value: number }> = [];

  /**
   * 表单字段栅格数
   */
  public formColSpan = 8;

  /**
   * 表单数据对象
   */
  public formData: {
    gradeId: number | null;
    gradeName: string;
    companyId: number | null;
  } = {
    gradeId: null,
    gradeName: '',
    companyId: null
  };

  /**
   * 表单验证规则
   */
  public formRules = {
    gradeId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('supplier.selectLevel')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  /**
   * 初始化标记
   */
  private isInitalized = false;

  /**
   * dialog开启事件
   */
  public handleDialogOpen(): void {
    if(!this.supplier) {
        throw new Error('supplier must not be null');
    }

    this.init();

    this.title = translation('supplier.modifyLevel');

    this.formData = {
      gradeId: this.supplier?.gradeId,
      companyId: this.supplier?.id,
      gradeName: this.supplier.gradeName
    }
  }

  /**
   * 提交数据
   */
  public handleSubmit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }

      this.openSubmitLoading();

      try {
        if (this.formData.gradeId !== this.supplier?.gradeId) {
          await supplierService.modifyLevel(Number(this.formData.companyId), Number(this.formData.gradeId));
        }

        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', {
          gradeName: this.gradeList?.find(level => level.value === this.formData.gradeId)?.label
        });
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.closeSubmitLoading();
      }
    });
  }

  /**
   * dialog关闭事件
   */
  public handleDialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.form as Form).resetFields();
  }

  /**
   * 初始化dialog所需数据
   */
  private init(): void {
    // 判断是否已初始化
    if (this.isInitalized) return;

    this.loadLevelData();
  }

  /**
   * 载入等级数据
   */
  private async loadLevelData(): Promise<void> {
    try {
      const gradeList = (await supplierLevelService.getAllSupplierLevels()) || [];
      this.gradeList = gradeList.map(grade => {
          return {
            label: grade.name,
            value: grade.id
          };
        });
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 开启loading
   */
  private openSubmitLoading(): void {
    this.submitLoading = true;
  }

  /**
   * 关闭loading
   */
  private closeSubmitLoading(): void {
    this.submitLoading = false;
  }

  /**
   * 关闭dialog
   */
  private closeDialog(): void {
    this.dialogVisible = false;
  }
}
